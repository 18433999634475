<template>
  <DefaultSection class="cta has-background-primary">
    <Container>
      <div class="columns">
        <div
          class="column is-8 has-text-centered-mobile title is-size-large has-text-white"
        >
          <span class="has-text-weight-medium">
            {{ title }}
          </span>
          <Typed :options="options">
            <span class="typing has-text-weight-bold" />
          </Typed>
          <!--          <vue-typed-js -->
          <!--            :strings="[animation]" -->
          <!--            :type-speed="100" -->
          <!--            :back-speed="50" -->
          <!--            :back-delay="6000" -->
          <!--            :show-cursor="true" -->
          <!--            :loop="true" -->
          <!--            cursor-char="_" -->
          <!--          > -->
          <!--            <span class="typing has-text-weight-bold" /> -->
          <!--          </vue-typed-js> -->
        </div>
        <div
          class="column is-3 is-offset-1-widescreen has-text-centered-mobile"
        >
          <DefaultLink
            v-if="link && link.url"
            :to="link.url.path"
            class="button has-text-weight-bold is-large is-size-normal mt-3 has-text-right has-text-centered-mobile"
            :class="getButtonClass"
          >
            {{ link.title }}
          </DefaultLink>
        </div>
      </div>
    </Container>
  </DefaultSection>
</template>

<script setup lang="ts">
import { Typed } from '@duskmoon/vue3-typed-js'
import type { TypedOptions } from '@duskmoon/vue3-typed-js'
import useButtonClass from '~/composables/useButtonClass'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  animation: {
    type: String,
    default: '',
  },
  link: {
    type: Object,
    default: () => {
      return {}
    },
  },
  color: {
    type: String,
    default: 'default',
  },
})
const options: TypedOptions = {
  strings: [props.animation],
  loop: true,
  typeSpeed: 100,
  showCursor: true,
  cursorChar: '_',
  backSpeed: 50,
  backDelay: 6000,
}

const getButtonClass = useButtonClass(props.color)
</script>

<style scoped lang="scss">
.cta {
  padding-top: toRem(40);
  padding-bottom: toRem(40);

  @include tablet {
    padding-top: toRem(100);
    padding-bottom: toRem(100);
  }

  .typed-element {
    display: inline;

    .typed-cursor {
      font-size: toRem(40);
      color: $white;
    }
  }
}
</style>
