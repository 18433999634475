<template>
  <DefaultSection class="is-content">
    <Container>
      <div class="columns">
        <div
          v-for="block in blocks"
          :key="block.id"
          class="column"
          :class="columnWith"
        >
          <template v-if="block.entity">
            <h3 class="is-title is-size-banner has-text-weight-bold mb-4">
              {{ block.entity.title }}
            </h3>
            <p class="has-text-weight-semibold is-size-normal">
              {{ block.entity.lead }}
            </p>
            <div
              v-if="block.entity.text && block.entity.text.processed"
              class="has-text-weight-medium is-size-normal content"
              v-html="block.entity.text.processed"
            />
            <DefaultLink
              v-if="block.entity.link && block.entity.link.url"
              :to="block.entity.link.url.path"
              class="button is-normal is-sky-blue is-outlined has-text-weight-bold mt-5"
            >
              {{ block.entity.link.title }}
            </DefaultLink>
          </template>
        </div>
      </div>
    </Container>
  </DefaultSection>
</template>

<script setup lang="ts">
const props = defineProps({
  blocks: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const columnWith = computed(() => {
  return `is-${12 / props.blocks.length}`
})
</script>

<style scoped lang="scss"></style>
