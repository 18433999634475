<template>
  <DefaultSection class="is-large">
    <Container>
      <h1
        class="is-title is-size-large has-text-weight-bold has-text-centered mb-4"
      >
        {{ title }}
      </h1>
      <p class="is-size-normal has-text-weight-medium has-text-centered mb-6">
        {{ lead }}
      </p>
      <div ref="priceList" class="columns is-multiline price-list equal-height-columns">
        <div
          v-for="(tier, index) in tiers"
          :key="tier.id"
          class="column is-one-quarter-desktop is-half is-flex"
        >
          <div v-if="tier.entity" class="price-box">
            <div class="main-info">
              <h3
                class="is-title is-size-4 has-text-weight-bold has-text-centered"
              >
                {{ tier.entity.title }}
              </h3>
              <h4
                class="is-size-normal has-text-weight-semibold mb-6 has-text-centered"
              >
                {{ tier.entity.price }}
              </h4>
              <p class="is-size-normal has-text-weight-medium mb-6">
                {{ tier.entity.lead }}
              </p>

              <DefaultLink
                v-if="tier.entity.link && tier.entity.link.url"
                :to="tier.entity.link.url.path"
                class="button is-large has-text-weight-bold is-size-6"
                :class="getPriceButtonClass(index)"
              >
                {{ tier.entity.link.title }}
              </DefaultLink>
            </div>
            <div class="subinfo">
              <div
                class="has-text-weight-medium is-size-normal content"
                v-html="tier.entity.features.processed"
              />
              <div class="has-text-centered">
                <button
                  v-if="tier.entity.text"
                  class="button is-outlined has-text-weight-bold is-outlined is-size-normal is-small mt-5"
                  :class="getInfoButtonClass(index)"
                  @click="infoModal(tier.entity.text.processed)"
                >
                  Mehr Infos
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </DefaultSection>
</template>

<script setup lang="ts">
import { useOruga } from '@oruga-ui/oruga-next'
import ParagraphPricesPriceDetails from '~/components/Paragraph/Prices/PriceDetails/index.vue'

defineProps({
  title: {
    type: String,
    default: '',
  },
  lead: {
    type: String,
    default: '',
  },
  tiers: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const oruga = useOruga()

function infoModal(content) {
  oruga.modal.open({
    component: ParagraphPricesPriceDetails,
    props: { content },
    trapFocus: true,
  })
}

const priceList = ref(null)

onMounted(() => {
  setEqualHeight()
  window.addEventListener('resize', setEqualHeight)
})

function setEqualHeight() {
  const elements = priceList.value.querySelectorAll('.main-info')
  let maxHeight = 0

  // Reset all heights
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.height = 'auto'
  }

  // Find the maximum height
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].offsetHeight > maxHeight) {
      maxHeight = elements[i].offsetHeight
    }
  }

  // Set the max height to all elements
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.height = `${maxHeight}px`
  }
}

function getPriceButtonClass(index) {
  let buttonClass = 'is-lessmess-desk'
  if (index === 0) {
    buttonClass = 'is-sky-blue'
  }
  return buttonClass
}

function getInfoButtonClass(index) {
  let buttonClass = 'is-sky-blue'
  if (index > 1) {
    buttonClass = 'is-white'
  }
  return buttonClass
}

// You can add any other logic or functions here
</script>

<style scoped lang="scss">
.price-list {
  .column:nth-child(2) {
    .price-box {
      background-color: $pale-gray-blue;
    }
  }
  .column:nth-child(3) {
    .price-box {
      background-color: $bright-gray-blue;
      color: $white;
    }
  }
  .column:nth-child(4) {
    .price-box {
      background-color: $gray-blue;
      color: $white;
    }
  }
}

.price-box {
  border-radius: 10px;
  background-color: $background-gray;
  padding: 2rem 2rem;
  box-shadow: 0 12px 32px -8px rgba(26, 26, 26, 0.24);
  .button {
    margin-top: auto;
  }

  .main-info {
    display: flex;
    flex-flow: column nowrap;
  }

  .subinfo {
    padding-top: 2rem;
    .content {
      ::v-deep(ol) {
        list-style-type: none;
        > li:before {
          content: '-';
          position: absolute;
          margin-left: -1rem;
        }
      }
    }
  }
}

.main-info {
  display: flex;
  flex-flow: column nowrap;
}
</style>
