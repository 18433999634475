<template>
  <div class="page">
    <DefaultSection v-if="!hideTitle" class="is-title">
      <Container>
        <h1 class="title is-size-2 has-text-weight-bold" :class="greyClass()">
          {{ title }}
        </h1>
      </Container>
    </DefaultSection>
    <template v-if="!hasSidebar">
      <Paragraph
        v-for="item in content"
        :key="item.id"
        :content="item.entity"
        :type="item.entity.__typename"
        :color="color"
      />
    </template>
    <template v-if="hasSidebar">
      <DefaultSection class="is-content">
        <Container>
          <div class="columns">
            <div class="column is-two-thirds-desktop">
              <ContactForm v-if="isContactForm" />
              <Paragraph
                v-for="item in content"
                :key="item.id"
                :content="item.entity"
                :type="item.entity.__typename"
                wrapper="div"
              />
            </div>
            <div :class="offsetClass()" class="column">
              <Paragraph
                v-for="item in sidebar"
                :key="item.id"
                :content="item.entity"
                :type="item.entity.__typename"
                wrapper="div"
              />
            </div>
          </div>
        </Container>
      </DefaultSection>
    </template>
  </div>
</template>

<script setup lang="ts">
import Paragraph from '~/components/Paragraph/index.vue'
import DefaultSection from '~/components/DefaultSection/index.vue'
import Container from '~/components/Container/index.vue'
import ContactForm from '~/components/ContactForm/index.vue'
import type { NodeFrontendPageFragment } from '#graphql-operations'
import type { EntityMetatags } from '~/helpers'

const props = defineProps({
  __typename: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
  nid: {
    type: Number,
    required: false,
  },
  metatags: {
    type: Array as PropType<EntityMetatags[]>,
    required: false,
  },
  hideTitle: {
    type: Boolean,
    required: false,
  },
  content: {
    type: Array as PropType<NodeFrontendPageFragment['content']>,
    default: () => [],
  },
  sidebar: {
    type: Array as PropType<NodeFrontendPageFragment['sidebar']>,
    default: () => [],
  },
  color: {
    type: String,
    required: false,
  },
})

const route = useRoute()

const hasSidebar = computed(() => (props.sidebar && props.sidebar.length > 0))
const currentRoutePath = `${route.path}`
const isContactForm = computed(() => strpos(currentRoutePath, '/contact', 0) === 0)
const isTestimonial = computed(() => strpos(currentRoutePath, '/testimonials', 0) === 0)

function strpos(haystack: string, needle: string, offset: number) {
  const i = (`${haystack}`).indexOf(needle, offset || 0)
  return i === -1 ? false : i
}

function offsetClass() {
  let offsetClass = ''
  if (isContactForm.value) {
    offsetClass = 'is-offset-1'
  }
  return offsetClass
}

function greyClass() {
  let greyClass = ''
  if (isTestimonial.value) {
    greyClass = 'has-text-grey-dark'
  }
  return greyClass
}
</script>

<style lang="scss">
.select,
.select select {
  width: 100%;
}
.message.is-success .message-body {
  color: $green;
}
</style>
