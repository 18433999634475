<template>
  <div class="carousel-wrapper">
    <Carousel
      :autoplay="5000"
      :pause-autoplay-on-hover="true"
      :items-to-show="1"
      :wrap-around="true"
      class="smirch-slider"
      snap-align="start"
      :breakpoints="breakpoints"
    >
      <Slide v-for="(item, index) in slides" :key="index">
        <template v-if="item && item.entity">
          <div class="has-text-centered">
            <figure class="image">
              <MediaImage
                v-if="item.entity.media"
                :image="item.entity.media.entity.image"
                style-name="smirch_detail_slider"
              />
            </figure>
            <div
              v-if="item.entity.media.entity.caption"
              class="mt-4 is-size-small has-text-centered has-text-weight-semibold image-caption"
            >
              {{ item.entity.media.entity.caption }}
            </div>
            <Status :status="item.entity.status" class="state" />
          </div>
        </template>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
defineProps({
  slides: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const breakpoints = {
  1440: {
    itemsToShow: 4,
  },
  990: {
    itemsToShow: 3,
  },
  768: {
    itemsToShow: 2,
  },
}
</script>

<style scoped lang="scss">
.carousel-wrapper {
  margin-left: toRem(95);
  margin-right: toRem(95);
}
.smirch-slider {
  --vc-nav-color: #{$grey-light};
  --vc-nav-color-hover: #{$grey-dark};
  --vc-nav-width: 50px;
  --vc-nav-height: 50px;
  $gap: 15px;

  width: calc(100% + 2 * #{$gap});
  margin: -$gap;

  .carousel__slide {
    min-width: 200px;
    padding: 0 $gap;
  }

  ::v-deep(.carousel__prev) {
    left: -80px;
    padding-bottom: 60px;
  }

  ::v-deep(.carousel__next) {
    right: -80px;
    padding-bottom: 60px;
  }
}

.state {
  margin-top: 1.5rem;
}
</style>
