<template>
  <span :class="status" class="status is-arial">
    <span class="dot" />{{ statusToName(status) }}
  </span>
</template>

<script setup lang="ts">
defineProps({
  status: {
    type: String,
    default: 'new',
  },
})

const statusToName = (status) => {
  switch (status) {
    case 'new':
      return 'erstellt'
    case 'assigned':
      return 'zugewiesen'
    case 'rejected':
      return 'abgewiesen'
    case 'done':
      return 'erledigt'
    default:
      return ''
  }
}
</script>

<style scoped lang="scss">
.status {
  color: $black;
  display: inline-block;
  padding: 0.2rem 0.75rem;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  .dot {
    background-color: $red;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 0.5rem;
  }

  &.new {
    background-color: $red-fill;
  }

  &.assigned {
    .dot {
      background-color: $orange;
    }
    background-color: $orange-fill;
  }

  &.done {
    .dot {
      background-color: $green;
    }
    background-color: $green-fill;
  }

  &.rejected {
    .dot {
      background-color: $grey;
    }
    background-color: $grey-lighter;
  }
}
</style>
