<template>
  <div class="hooper-carousel">
    <Carousel
      :autoplay="5000"
      :pause-autoplay-on-hover="true"
      class="explanation-slider"
      :items-to-show="1"
      :wrap-around="true"
      snap-align="start"
      :breakpoints="breakpoints"
    >
      <Slide v-for="(item, index) in slides" :key="index">
        <template v-if="item.entity">
          <div class="has-text-centered">
            <figure class="image">
              <MediaImage
                v-if="item.entity.image"
                :image="item.entity.image"
                style-name="smirch_detail_slider"
                class="image image-shadow"
              />
            </figure>
            <div
              class="mt-4 mb-4 is-size-small has-text-centered has-text-weight-semibold image-caption"
            >
              <span>{{ item.entity.caption }}</span>
            </div>
          </div>
        </template>
      </Slide>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
defineProps({
  slides: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const breakpoints = {
  990: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
  768: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
}
</script>

<style scoped lang="scss">
.explanation-slider {
  --vc-nav-color: #{$grey-light};
  --vc-nav-color-hover: #{$grey-dark};
  --vc-nav-width: 50px;
  --vc-nav-height: 50px;

  $gap: 30px;

  width: calc(100% + 2 * #{$gap});
  margin: -$gap;

  .carousel__slide {
    padding: 0 $gap;
  }
}

.hooper-carousel {
  $gap: 30px;

  .hooper {
    width: calc(100% + 2 * #{$gap});
    margin: -$gap;
    outline: none !important;
    height: auto !important;

    ::v-deep(.hooper-slide) {
      padding: 0 $gap;
      min-width: 200px;
    }
  }
}

.state {
  margin-top: 1.5rem;
}
</style>
