<template>
  <component :is="getSection" class="is-content">
    <component :is="getContainer">
      <div class="columns">
        <div class="column" :class="getWidth">
          <div class="content" v-html="getText" />
        </div>
      </div>
    </component>
  </component>
</template>

<script setup lang="ts">
import Container from '~/components/Container/index.vue'
import DefaultSection from '~/components/DefaultSection/index.vue'

const props = defineProps({
  text: {
    type: Object,
    default: () => {
      return {}
    },
  },
  wrapper: {
    type: String,
    default: '',
  },
})

const getText = computed(() => {
  return props.text?.processed || ''
})

const getContainer = computed(() => {
  return props.wrapper || Container
})

const getSection = computed(() => {
  return props.wrapper || DefaultSection
})

const getWidth = computed(() => {
  return getContainer.value === Container ? 'is-two-thirds-desktop' : ''
})
</script>

<style scoped lang="scss"></style>
