<template>
  <div v-html="src" />
</template>

<script setup lang="ts">
import { buildRokkaUrl } from '~/helpers'
import type { MediaImageFragment } from '#graphql-operations'

const props = defineProps({
  assetUrl: {
    type: String,
    default: '',
  },
  styleName: {
    type: String,
    required: false,
  },
  loading: {
    type: String,
    default: 'lazy',
  },
  image: {
    type: Object as PropType<MediaImageFragment['image']>,
    required: false,
  },
})

const src = ref('')

const config = useRuntimeConfig()

const url = computed(() => {
  if (props.image.rokkaHash) {
    // Rokka stack with source_file = true;
    const stack = 'svg'
    return buildRokkaUrl(
      stack,
      '',
      props.image.rokkaHash,
      props.image.rokkaFilename,
      config.public.rokkaOrg,
    )
  }
  else {
    return props.assetUrl
  }
})

async function fetchSrc() {
  await fetch(url.value)
    .then(r => r.text())
    .then((srcValue) => {
      src.value = srcValue
    })
}

onMounted(fetchSrc)
</script>

<style scoped lang="scss"></style>
