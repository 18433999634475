<template>
  <div>
    <div v-if="display === 'minimal'" class="testimonials content">
      <h3 class="title is-5">
        {{ title }}
      </h3>
      <p
        v-for="testimonial in filteredTestimonials"
        :key="testimonial.id"
        class="testimonial"
      >
        {{ testimonial.name }}<br>
        <nuxt-link :to="testimonial.url">
          {{ testimonial.title }}
        </nuxt-link>
      </p>
    </div>
    <div v-if="display === 'standard'">
      <ParagraphTestimonialListStandard
        v-for="testimonial in filteredTestimonials"
        :key="testimonial.id"
        v-bind="testimonial"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  display: {
    type: String,
    default: '',
  },
  nid: {
    type: Number,
    default: 0,
  },
})

// Get the data.
const { data: testimonials, errors } = await useAsyncData(async () => {
  const variables = {}
  return useGraphqlQuery('testimonialList', variables).then((v) => {
    return v.data.nodeQuery?.entities || []
  })
})

const filteredTestimonials = computed(() => {
  return testimonials.value.filter(
    testimonial => testimonial.id !== props.nid,
  )
})
</script>

<style scoped lang="scss">
.testimonials {
  p {
    margin-bottom: 2rem;
  }
}
</style>
