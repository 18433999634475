<template>
  <component
    :is="component"
    v-if="component && entity"
    :key="$route.path"
    v-bind="entity"
  />
</template>

<script lang="ts" setup>
import NodeFrontendPage from '~/components/Node/FrontendPage/index.vue'
import NodeSmirch from '~/components/Node/Smirch/index.vue'
import NodeTestimonial from '~/components/Node/Testimonial/index.vue'

definePageMeta({
  name: 'drupal-route',
  path: '/:slug(.*)*',
})

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, async () => {
  return useGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => {
    return v.data
  })
})

// Handles redirects and metatags.
const entity = await useDrupalRoute<NodeFrontendPageFragment | NodeSmirchFragment | NodeTestimonialFragment >(
  query.value,
)

/**
 * The vue component for the entity.
 */
const component = computed(() => {
  if (query.value?.route?.__typename === 'EntityCanonicalUrl') {
    if (query.value.route.entityGlobal?.__typename === 'NodeFrontendPage') {
      return NodeFrontendPage
    }
    else if (query.value.route.entityGlobal?.__typename === 'NodeSmirch') {
      return NodeSmirch
    }
    else if (query.value.route.entityGlobal?.__typename === 'NodeTestimonial') {
      return NodeTestimonial
    }
  }
})
</script>

<style lang="scss"></style>
