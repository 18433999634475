<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    id="contactForm"
    type="form"
    :actions="false"
    use-local-storage
    @submit="onSubmit"
  >
    <div class="columns is-multiline">
      <FormKit
        v-model="form.salutation"
        type="select"
        validation="required"
        name="salutation"
        label="Anrede"
        class="column is-half small-padding"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
        :options="{
          '': 'Bitte wählen',
          'male': 'Herr',
          'female': 'Frau',
        }"
      />

      <FormKit
        v-model="form.email"
        type="email"
        validation="required|email"
        name="email"
        label="E-Mail"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
      />
      <FormKit
        v-model="form.firstname"
        validation="required"
        type="text"
        name="firstname"
        label="Vorname"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
      />
      <FormKit
        v-model="form.lastname"
        validation="required"
        type="text"
        name="lastname"
        label="Nachname"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
      />
      <FormKit
        v-model="form.company"
        type="text"
        name="company"
        label="Unternehmen / Gemeinde"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
      />
      <FormKit
        v-model="form.position"
        type="text"
        name="position"
        label="Position"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
      />
      <FormKit
        v-model="form.category"
        type="select"
        validation="required"
        name="category"
        label="Anliegen"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
        :options="{
          '': 'Bitte wählen',
          'demo': 'Demo',
          'support': 'Support',
          'contact': 'Kontaktaufnahme',
          'feedback': 'Feedback',
          'other': 'Anderes',
        }"
      />
      <FormKit
        v-model="form.phone"
        type="text"
        name="phone"
        label="Telefon"
        :classes="{
          outer: '$reset column is-half small-padding',
        }"
      />
      <p class="is-hidden">
        <label>Don’t fill this out if you're human: <input name="fake-field"></label>
      </p>

      <FormKit
        v-model="form.message"
        validation="required"
        type="textarea"
        name="message"
        label="Nachricht"
        :classes="{
          outer: '$reset column is-full small-padding',
        }"
      />
    </div>
    <p class="is-size-7-tablet is-size-6 mb-4">
      Durch das Klicken auf "Anfrage senden" erklären sie sich einverstanden mit
      unseren
      <nuxt-link to="/tos">
        Nutzungsbedingungen
      </nuxt-link>
      und unserer
      <nuxt-link to="/privacy">
        Datenrichtlinie
      </nuxt-link>
      .
    </p>
    <Message
      v-for="(message, index) in messages"
      :key="index"
      title="Hinweis"
      :type="message.type"
      class="mb-6"
    >
      {{ message.message }}
    </Message>
    <div class="control">
      <FormKit
        :disabled="state.isSending"
        type="submit"
      >
        Anfrage senden
      </FormKit>
    </div>
  </FormKit>
</FormKitLazyProvider>

</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { reset } from '@formkit/core'

const form = reactive({
  salutation: '',
  email: '',
  firstname: '',
  lastname: '',
  company: '',
  position: '',
  category: '',
  phone: '',
  message: '',
  webform_id: 'contact_form',
})

const messages = ref([])
const state = reactive({
  isSending: false,
})

const onSubmit = async () => {
  state.isSending = true

  const variables = {
    values: JSON.stringify(form),
  }

  try {
    const { data } = await useGraphqlMutation('submitContactForm', variables)
    if (data.submitForm?.submission?.id) {
      messages.value.push({
        type: 'is-success',
        message: 'Besten Dank für ihre Anfrage, wir werden uns umgehend bei Ihnen melden.',
      })
    }
    else {
      let errorMessages = ''
      if (data.submitForm.errors) {
        errorMessages = data.submitForm.errors.join(' \r\n')
        messages.value.push({
          type: 'is-danger',
          message: errorMessages,
        })
      }
    }
  }
  catch (error) {
    messages.value.push({
      type: 'is-danger',
      message: `Es ist ein Fehler aufgetreten.${error}`,
    })
  }

  reset('contactForm')
}
</script>

<style scoped lang="scss">
.small-padding {
  padding-top: 0;
}
</style>
