<template>
  <DefaultSection class="hero has-background-sky-blue-8">
    <MediaImage
      v-if="media && media.entity && media.entity.image"
      :image="media.entity.image"
      style-name="paragraph_hero_image_box"
      class="hero-image is-hidden-mobile"
    />
    <div class="pulse-bomb is-hidden-mobile">
      <button
        v-if="video && video.entity"
        class="button has-text-weight-bold is-lessmess-desk is-size-normal"
        @click="isComponentModalActive = true"
      >
        <span class="icon">
          <Icon name="fa6-solid:play" />
        </span>
        <span>Video zeigen</span>
      </button>
    </div>
    <Container>
      <div class="columns">
        <div class="column is-full is-half-tablet has-text-centered-mobile">
          <h1 class="title is-size-intro has-text-weight-bold">
            {{ title }}
          </h1>
          <div
            v-if="intro && intro.processed"
            class="is-size-5 mobile has-text-weight-bold teaser-text"
            v-html="intro.processed"
          />

          <onboarding-app-buttons />

          <button
            v-if="video && video.entity"
            class="button has-text-weight-bold is-size-normal is-lessmess-desk mt-5 is-hidden-tablet"
            @click="isComponentModalActive = true"
          >
            <span class="icon">
              <Icon name="fa6-solid:play" />
            </span>
            <span>Video zeigen</span>
          </button>
        </div>
      </div>
      <ClientOnly>
        <o-modal
          v-if="isComponentModalActive"
          v-model:active="isComponentModalActive" teleport
        >
          <MediaRemoteVideo v-if="video && video.entity" :video="video.entity" />
        </o-modal>
      </ClientOnly>
    </Container>
  </DefaultSection>
</template>

<script setup lang="ts">
defineProps({
  title: {
    type: String,
    default: '',
  },
  intro: {
    type: Object,
    default: () => {
      return {}
    },
  },
  link: {
    type: Object,
    default: () => {
      return {}
    },
  },
  media: {
    type: Object,
    default: () => {
      return {}
    },
  },
  video: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const isComponentModalActive = ref(false)
</script>

<style scoped lang="scss">
.hero {
  padding-top: toRem(60);
  padding-bottom: toRem(60);

  @include tablet {
    padding-top: toRem(80);
    padding-bottom: toRem(80);
    min-height: calc(410px + 160px);
  }

  @include widescreen {
    padding-top: toRem(120);
    padding-bottom: toRem(100);
    min-height: calc(560px + 220px);
  }

  .hero-image {
    @include tablet {
      display: block;
      position: absolute;
      left: calc(50% + 1rem);
      ::v-deep(img) {
        max-width: unset;
      }
    }
  }

  .pulse-bomb {
    display: block;
    position: absolute;
    z-index: 10;
    left: calc(50% + 1rem);
    margin-left: 240px;
    margin-top: 130px;

    //animation: pulse 2s infinite;

    button {
      box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
      animation: pulsing 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
    }

    @include desktop {
      margin-left: 260px;
      margin-top: 140px;
    }

    @include widescreen {
      margin-left: 360px;
      margin-top: 200px;
    }
  }

  .mobile-centered {
    @include mobile {
      justify-content: center;
    }
  }

  h1.title {
    line-height: 1.222;
  }

  .teaser-text {
    margin-right: 2rem;

    ::v-deep(p) {
      margin-bottom: 0.75rem;
    }

    @include desktop {
      margin-right: 6rem;
    }
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}
</style>
