<template>
  <DefaultSection class="section is-content">
    <div class="container">
      <div
        v-if="intro"
        class="intro is-size-3 has-text-weight-bold has-special-bold has-text-centered mb-6"
        v-html="intro.processed"
      />
      <p
        class="has-text-centered has-text-weight-semibold has-text-grey-dark content"
      >
        {{ subtitle }}
      </p>
      <div class="images">
        <ParagraphExplanationCarousel
          :slides="keyImages"
        />
      </div>
      <div class="columns">
        <div class="column is-offset-2 is-8">
          <div
            v-for="(statement, key) in statements"
            :key="key"
            class="has-text-centered has-text-weight-bold is-size-balanced"
          >
            <div v-if="statement" v-html="statement.processed" />
            <hr v-if="last !== key" class="small">
          </div>
          <div class="has-text-centered has-text-weight-bold mt-6">
            <menu-button title="App downloaden" />
          </div>
        </div>
      </div>
    </div>
  </DefaultSection>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  content: {
    type: Object,
    default: () => {
      return {}
    },
  },
  intro: {
    type: Object,
    default: () => {
      return {
        processed: '',
      }
    },
  },
  subtitle: {
    type: String,
    default: '',
  },
  keyImages: {
    type: Array,
    default: () => {
      return []
    },
  },
  statements: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const last = computed(() => props.statements.length - 1)

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}
</script>

<style scoped lang="scss">
.intro {
  &.has-special-bold {
    ::v-deep(strong) {
      font-weight: $weight-bold;
    }
  }
}

hr.small {
  height: 4px;
  background-color: $yellow;
  border: none;
  width: 20%;
  margin: 2rem auto;
}

.images {
  margin-top: toRem(60);
  margin-bottom: toRem(40);

  @include desktop {
    margin-top: toRem(80);
    margin-bottom: toRem(60);
  }
}
</style>
