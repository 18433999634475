<template>
  <DefaultSection class="is-content">
    <Container>
      <div class="columns" :class="direction">
        <div
          class="column is-half"
          :class="{ 'is-offset-1': float === 'is-pulled-left' }"
        >
          <div class="columns">
            <div v-if="svgIcon" class="column is-2 icon-column">
              <MediaSvgIcon
                v-if="svgIcon"
                :image="svgIcon.entity.image"
                class="image-icon"
              />
            </div>
            <div class="column text-column">
              <h1
                class="title is-size-medium has-text-weight-bold has-text-primary"
              >
                {{ title }}
              </h1>
              <p class="mb-5 has-text-weight-semibold is-size-normal" v-html="lead" />
            </div>
          </div>
        </div>
        <div
          class="column"
          :class="{ 'is-offset-1': float === 'is-pulled-right' }"
        >
          <div class="image-wrapper">
            <MediaImage
              :image="media.entity.image"
              style-name="paragraph_text_image"
              class="image image-shadow"
            />
            <div
              v-if="media.entity.caption"
              class="mt-4 is-size-6 is-size-7-desktop has-text-centered has-text-weight-semibold image-caption"
            >
              {{ media.entity.caption }}
            </div>
          </div>
        </div>
      </div>
    </Container>
  </DefaultSection>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  lead: {
    type: String,
    default: '',
  },
  media: {
    type: Object,
    default: () => {
      return {}
    },
  },
  svgIcon: {
    type: Object,
    default: () => {
      return {}
    },
  },
  float: {
    type: String,
    default: '',
  },
})

const direction = computed(() => {
  let reverse = ''
  if (props.float === 'is-pulled-left') {
    reverse = 'reverse-columns'
  }
  return reverse
})
</script>

<style scoped lang="scss">
@include tablet {
  .reverse-columns {
    flex-direction: row-reverse;
    display: flex;
  }
}

.icon-column {
  @include tablet {
    margin-bottom: toRem(40);
  }
}

.icon-column + .text-column {
  margin-top: -20px;
  @include tablet {
    margin-top: toRem(40);
  }
}

.image-wrapper {
  padding-left: toRem(60);
  padding-right: toRem(60);
  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.image-icon {
  ::v-deep(svg) {
    width: 35px;
    height: 35px;
  }
  padding: 0 0.25rem;

  @include tablet {
    position: relative;
    right: -50%;
    margin-top: 0;
    ::v-deep(svg) {
      width: 56px;
      height: 56px;
    }
  }
}
</style>
