<template>
  <DefaultSection class="has-border-section">
    <Container>
      <Carousel
        :autoplay="5000"
        :pause-autoplay-on-hover="true"
        class="testimonial-slider"
        :wrap-around="true"
      >
        <Slide v-for="slide in slides" :key="slide.id">
          <section v-if="slide" class="hero">
            <div class="hero-body has-text-centered">
              <MediaImage
                v-if="slide.logo"
                :image="slide.logo.entity.image"
                style-name="paragraph_logos_fill"
              />
              <div class="columns">
                <div class="column is-10 is-offset-1">
                  <nuxt-link :to="slide.url.path">
                    <div
                      class="is-size-slider has-text-weight-semibold my-5 has-special-bold"
                      v-html="slide.text.processed"
                    />
                    <div>{{ slide.name }}, {{ slide.role }}</div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </section>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </Container>
  </DefaultSection>
</template>

<script setup lang="ts">
// Get the data.
const { data: slides } = await useAsyncData(async () => {
  const variables = {}
  return useGraphqlQuery('testimonials', variables).then((v) => {
    return v.data.nodeQuery?.entities || []
  })
})
</script>

<style scoped lang="scss">
.section {
  padding-top: 0;
  padding-bottom: toRem(80);
}

.testimonial-slider {
  --vc-nav-color: #{$grey-light};
  --vc-nav-color-hover: #{$grey-dark};
  --vc-nav-width: 50px;
  --vc-nav-height: 50px;
  a {
    color: $body-color;
  }

  ::v-deep(.carousel__prev) {
    left: -80px;
  }

  ::v-deep(.carousel__next) {
    right: -80px;
  }
}
</style>
