<template>
  <section class="section video-hero has-background-sky-blue-8">
    <div class="container">
      <div class="columns">
        <div class="column is-5">
          <div class="img-container">
            <MediaImage
              v-if="image.entity && image.entity.image"
              :image="image.entity.image"
              style-name="paragraph_hero_video"
              class="video-background"
            />
            <p class="caption has-text-weight-semibold has-text-centered">
              {{ caption }}
            </p>

            <div class="centered-button">
              <button
                v-if="video && video.entity"
                class="button has-text-weight-bold is-lessmess-desk is-size-normal"
                @click="isComponentModalActive = true"
              >
                <span class="icon">
                  <Icon name="fa6-solid:play" />
                </span>
                <span>Video zeigen</span>
              </button>
            </div>
          </div>
        </div>
        <div class="column is-offset-1">
          <h1
            class="title is-size-banner has-text-weight-bold has-text-primary has-text-centered-mobile"
          >
            {{ title }}
          </h1>
          <div
            v-if="text && text.processed"
            class="mb-5 has-text-weight-semibold is-size-normal content"
            v-html="text.processed"
          />

          <div class="has-text-centered-mobile">
            <DefaultLink
              v-if="link && link.url"
              :to="link.url.path"
              class="button has-text-weight-bold is-size-normal mt-5"
              :class="getButtonClass"
              :target="link.target"
            >
              {{ link.title }}
            </DefaultLink>
          </div>
        </div>
      </div>
    </div>
    <o-modal v-if="isComponentModalActive" v-model:active="isComponentModalActive" teleport>
      <MediaRemoteVideo v-if="video && video.entity" :video="video.entity" />
    </o-modal>
  </section>
</template>

<script setup lang="ts">
import useButtonClass from '~/composables/useButtonClass'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  lead: {
    type: String,
    default: '',
  },
  caption: {
    type: String,
    default: '',
  },
  text: {
    type: Object,
    default: () => {
      return {
        processed: '',
      }
    },
  },
  image: {
    type: Object,
    default: () => {
      return {}
    },
  },
  video: {
    type: Object,
    default: () => {
      return {}
    },
  },
  noCrop: {
    type: Boolean,
    default: false,
  },
  svgIcon: {
    type: Object,
    default: () => {
      return {}
    },
  },
  link: {
    type: Object,
    default: () => {
      return {}
    },
  },
  color: {
    type: String,
    default: 'default',
  },
})
defineEmits({
  'update:isComponentModalActive': (value: boolean) => typeof value === 'boolean',
})
const getButtonClass = useButtonClass(props.color)
const isComponentModalActive = ref(false)
</script>

<style scoped lang="scss">
.section.video-hero {
  padding-top: toRem(60);
  padding-bottom: toRem(60);

  @include tablet {
    padding-top: toRem(80);
    padding-bottom: toRem(80);
  }

  @include widescreen {
    padding-top: toRem(120);
    padding-bottom: toRem(100);
  }

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    picture {
      border: 3px solid $white;
      box-shadow: 0 12px 32px -8px rgba(26, 26, 26, 0.7);
    }
    :deep(img) {
      filter: brightness(0.7);
      margin-bottom: -7px;
    }
  }

  .caption {
    position: relative;
    margin-top: 3rem;
  }

  .centered-button {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3rem;
  }
}
</style>
