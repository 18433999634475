<template>
  <!-- we cannot use Section because of an error by the map in prod build -->
  <div class="section is-medium">
    <!-- we cannot use Container because of an error by the map in prod build -->
    <div class="container">
      <h1 class="title is-2 has-text-weight-bold has-text-centered">
        <span class="overline">{{ rayonTitle }}</span> <br>
        {{ title }}
      </h1>

      <NodeSmirchImageSlider :images="images" />

      <div class="columns">
        <div class="column is-offset-2-tablet is-8 mt-4">
          <table class="table details is-fullwidth">
            <tbody>
              <tr v-if="description">
                <th>Beschreibung:</th>
                <td>{{ description }}</td>
              </tr>
              <tr v-if="prioName">
                <th>Priorität:</th>
                <td>{{ prioName }}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td><Status :status="statusRemap(statusValue)" /></td>
              </tr>
              <tr>
                <th>Adresse:</th>
                <td>
                  {{ address.addressLine1 }},
                  <span v-if="address.addressLine2">{{ address.addressLine2 }},</span>
                  {{ address.postalCode }} {{ address.locality }}
                </td>
              </tr>
              <tr>
                <th>Areal:</th>
                <td>{{ place }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="columns">
        <div class="column is-offset-2 is-8 mt-4">
          <div id="map-wrap">
            <LMap
              ref="map"
              :options="{ scrollWheelZoom: false }"
              :zoom="15"
              :center="[position.lat, position.lon]"
            >
              <LTileLayer
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              <LMarker :lat-lng="[position.lat, position.lon]" />
            </LMap>
          </div>
        </div>
      </div>

      <div class="columns mt-4 is-multiline">
        <div class="column is-offset-2 is-6">
          <h3 class="title is-5">
            Kommentare:
          </h3>
        </div>
        <div class="column is-2">
          <button
            class="button is-fullwidth is-small is-sky-blue is-outlined has-text-weight-bold"
            @click="goto('add-comment')"
          >
            kommentieren
          </button>
        </div>
        <div class="column is-offset-2 is-8">
          <CommentComment
            v-for="comment in commentList"
            v-bind="comment"
            :key="comment.cid"
          />
          <div ref="add-comment">
            <CommentForm :nid="nid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  CommentCommentFragment,
  NodeRayonDataFragment,
  NodeSmirchFragment,
} from '#graphql-operations'
import type { EntityMetatags } from '~/helpers'

const props = defineProps({
  __typename: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
  nid: {
    type: Number,
    required: false,
  },
  metatags: {
    type: Array as PropType<EntityMetatags[]>,
    required: false,
  },
  description: {
    type: String,
    default: '',
    required: false,
  },
  position: {
    type: Object as PropType<NodeSmirchFragment['position']>,
    required: false,
  },
  media: {
    type: Object as PropType<NodeSmirchFragment['media']>,
    required: false,
  },
  additional: {
    type: Object as PropType<NodeSmirchFragment['additional']>,
    required: false,
  },
  done: {
    type: Object as PropType<NodeSmirchFragment['done']>,
    required: false,
  },
  rayons: {
    type: Object as PropType<NodeSmirchFragment['rayons']>,
    required: false,
  },
  address: {
    type: Object as PropType<NodeSmirchFragment['address']>,
    required: false,
  },
  status: {
    type: String,
    default: '',
    required: false,
  },
  statusValue: {
    type: String,
    default: '',
    required: false,
  },
  where: {
    type: Object as PropType<NodeSmirchFragment['where']>,
    required: false,
  },
  priority: {
    type: Object as PropType<NodeSmirchFragment['priority']>,
    required: false,
  },
})

// Get the data.
const { data: comments } = await useAsyncData(async () => {
  const random = (Math.random() + 1).toString(36).substring(7)
  const variables = {
    nid: props.nid,
    random,
  }
  return useGraphqlQuery('commentsPerNode', variables).then((v) => {
    return (v.data.commentQuery?.entities || []) as CommentCommentFragment[]
  })
})

const rayonTitle = computed(() => {
  if (props.rayons?.length) {
    return (props.rayons[0]?.entity as NodeRayonDataFragment).title
  }
})

const commentList = computed(() => {
  if (comments.value.length) {
    return comments.value.filter((el: any) => el != null)
  }
  return []
})

const images = computed(() => {
  const images = []

  if (props.done?.entity) {
    images.push(props.done?.entity)
  }

  images.push(props.media?.entity)

  props.additional?.forEach((item) => {
    images.push(item?.entity)
  })

  return images
})

const place = computed(() => props.where?.entity?.label)

const prioName = computed(() => props.priority?.entity?.label)

function goto(refName: string) {
  const element = this.$refs[refName]
  // @ts-expect-error
  element?.scrollIntoView({ behavior: 'smooth' })
}

function statusRemap(status: string) {
  switch (status) {
    case 'healing_workflow_new':
      return 'new'
    case 'healing_workflow_assigned_wizzi':
    case 'healing_workflow_in_progress':
    case 'healing_workflow_done_healy':
      return 'assigned'
    case 'healing_workflow_deferred':
    case 'healing_workflow_deleted':
      return 'rejected'
    case 'healing_workflow_done_wizzi':
    case 'healing_workflow_closed':
      return 'done'
    default:
      return ''
  }
}

onMounted(() => {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'))
  }, 250)
})
</script>

<style lang="scss" scoped>
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');

.vue2leaflet-map {
  z-index: 0;
}

.overline {
  color: $grey-dark;
}

#map-wrap {
  height: 100%;
  width: 100%;
  min-height: 320px;
  max-height: 340px;

  @include mobile {
    height: 300px;
  }
}

.table.details {
  border: 1px solid $grey-light;
  border-width: 1px 0;

  th {
    min-width: 190px;
  }

  td {
    font-weight: 600;
    color: $grey-dark;
  }

  th,
  td {
    padding-left: 0;
    padding-right: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
