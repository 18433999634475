<template>
  <div class="comment-form">
    
<FormKitLazyProvider config-file="true">
<h4 class="title is-size-6 grey-header">
      Neuen Kommentar hinzufügen:
    </h4>

    <Message
      v-for="(message, index) in messages"
      :key="index"
      title="Hinweis"
      :type="message.type"
      aria-close-label="Schliessen"
      class="mb-6"
    >
      <p v-html="message.message" />
    </Message>

    <FormKit
      id="commentForm"
      type="form"
      use-local-storage
      :actions="false"
      @submit="onSubmit"
    >
      <div class="columns is-multiline">
        <FormKit
          id="username"
          v-model="form.name"
          autocomplete="username"
          name="username"
          validation="required"
          label="Name"
          :classes="{
            outer: '$reset column is-full small-padding',
          }"
        />

        <FormKit
          v-model="form.mail"
          validation="required|email"
          type="email"
          name="mail"
          label="E-Mail"
          :classes="{
            outer: '$reset column is-full small-padding',
          }"
        />
        <FormKit
          v-model="form.subject"
          validation="required"
          type="text"
          name="subject"
          label="Betreff"
          :classes="{
            outer: '$reset column is-full small-padding',
          }"
        />
        <p class="is-hidden">
          <label>Don’t fill this out if you're human: <input name="fake-field"></label>
        </p>

        <FormKit
          v-model="form.comment"
          validation="required"
          type="textarea"
          name="comment"
          label="Kommentar"
          :classes="{
            outer: '$reset column is-full small-padding',
          }"
        />
      </div>
      <p class="is-size-7-tablet is-size-6 mb-4">
        Durch das Klicken auf "Kommentar senden" erklären sie sich einverstanden
        mit unseren
        <nuxt-link to="/tos">
          Nutzungsbedingungen
        </nuxt-link>
        und unserer
        <nuxt-link to="/privacy">
          Datenrichtlinie
        </nuxt-link>
        .
      </p>
      <div class="control">
        <FormKit
          type="submit"
          :disabled="state.isSending"
        >
          Kommentar senden
        </FormKit>
      </div>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { reset } from '@formkit/core'
import useGoogleRecaptcha, { RecaptchaAction } from '~/composables/useGoogleRecaptcha'

const props = defineProps({
  nid: {
    type: Number,
    default: 0,
  },
})

const form = ref({
  name: '',
  mail: '',
  subject: '',
  comment: '',
  fieldName: 'comment',
  entityType: 'node',
})

const messages = ref([])
const state = ref({
  isSending: false,
})

const { executeRecaptcha } = useGoogleRecaptcha()

const refreshing = ref(false)
async function refreshAll() {
  refreshing.value = true
  try {
    await refreshNuxtData()
  }
  finally {
    refreshing.value = false
  }
}

const onSubmit = async () => {
  state.value.isSending = true

  let token = ''
  try {
    token = await executeRecaptcha(RecaptchaAction.social)
  }
  catch (e) {}

  const variables = {
    input: { ...form.value, ...{ nid: props.nid } },
    token,
  }

  await useGraphqlMutation('createComment', variables)
    .then((v) => {
      if (v.data.createCommentComment.entity?.entityId) {
        messages.value = []
        messages.value.push({
          type: 'is-success',
          message: 'Besten Dank für Ihren Kommentar.',
        })
      }
      else {
        let errorMessages = ''
        if (v.data.createCommentComment.errors.length) {
          errorMessages = v.data.createCommentComment.errors.join(' \r\n')
          messages.value.push({
            type: 'is-danger',
            message: errorMessages,
          })
        }
        if (v.data.createCommentComment.violations.length) {
          v.data.createCommentComment.violations.forEach((
            violation,
          ) => {
            messages.value.push({
              type: 'is-danger',
              message: violation.message,
            })
          })
        }
      }
    })
    .catch((error) => {
      messages.value.push({
        type: 'is-danger',
        message: `Es ist ein Fehler aufgetreten.${error}`,
      })
    })

  state.value.isSending = false

  reset('commentForm')
  await refreshAll()
}
</script>

<style scoped lang="scss">
.comment-form {
  border-top: 2px solid $grey-lighter;
  padding-top: 2rem;
}

.small-padding {
  padding-top: 0;
}

.grey-header {
  color: $grey-dark;
  font-weight: 600;
  margin-bottom: 3rem;
}
</style>
