<template>
  <section class="section is-content cta has-background-primary">
    <div class="container">
      <div class="columns">
        <div
          class="column is-4 has-text-centered has-text-white is-hidden-mobile"
        >
          <div class="image-wrapper rounded">
            <MediaImage
              v-if="media.entity && media.entity.image"
              :image="media.entity.image"
              style-name="paragraph_circle_image"
              class="image"
            />
          </div>
          <MediaSvgIcon
            v-if="svgIcon"
            :image="svgIcon.entity.image"
            class="svg-image mt-6"
          />
          <div
            v-if="svgIcon && svgIcon.entity.image && svgIcon.entity.caption"
            class="is-size-6 is-size-5-desktop has-text-centered has-text-weight-semibold image-caption"
          >
            {{ svgIcon.entity.caption }}
          </div>
        </div>

        <div class="column has-text-white has-text-centered-mobile">
          <h1 class="title is-size-medium has-text-weight-bold has-text-white">
            {{ title }}
          </h1>
          <div
            v-if="text && text.processed"
            class="mb-5 has-text-weight-semibold is-size-normal content"
            v-html="text.processed"
          />
          <div class="has-text-weight-bold mt-6">
            <menu-button :title="linkTitle" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  lead: {
    type: String,
    default: '',
  },
  text: {
    type: Object,
    default: () => {
      return {
        processed: '',
      }
    },
  },
  media: {
    type: Object,
    default: () => {
      return {}
    },
  },
  noCrop: {
    type: Boolean,
    default: false,
  },
  svgIcon: {
    type: Object,
    default: () => {
      return {}
    },
  },
  link: {
    type: Object,
    default: () => {
      return {}
    },
  },
})

const linkTitle = computed(() => {
  if (props.link && props.link.title) {
    return props.link.title
  }
  return 'Jetzt App downloaden!'
})
</script>

<style scoped lang="scss">
.cta {
  padding-top: toRem(40);
  padding-bottom: toRem(40);

  @include tablet {
    padding-top: toRem(100);
    padding-bottom: toRem(100);
  }

  .rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    picture {
      width: 220px;
      border: 4px solid $white;
      border-radius: 50%;
      display: flex;
      ::v-deep(img) {
        border-radius: 50%;
      }
    }
  }

  .svg-image {
    ::v-deep(svg) {
      max-width: 60px;
      max-height: 60px;
    }
  }
}
</style>
