import type { Head, Link, Meta } from '@unhead/schema'
import type {
  MetatagAttributeFragment,
  MetatagFragment,
  RouteEntityFragment,
  RouteQuery,
  UseDrupalRouteFragment,
} from '#graphql-operations'

/**
 * Get the page title from the Drupal metatags.
 */
export function getTitle(tag: MetatagFragment): string | undefined {
  if (tag.id !== 'title') {
    return
  }

  const value = tag.attributes.find(v => v.key === 'content')
  if (!value) {
    return
  }

  return value.value
}

/**
 * Build the vue-meta object given the Drupal metatag attributes array.
 *
 * The input is an array of e.g.
 * [{ key: 'one', value: 'foo' }, { key: 'two', value: 'bar'  }]
 *
 * The output is an object where each of the key/value pairs is reduced to a
 * single object:
 * { one: 'foo', two: 'bar' }
 */
export function getTagObject(
  attributes: MetatagAttributeFragment[],
): Link | Meta {
  return attributes.reduce((acc, v) => {
    acc[v.key] = v.value
    return acc
  }, {} as any)
}

/**
 * Maps the Drupal metatags to vue-meta compatible definitions.
 */
export function getTags(metatags: MetatagFragment[]): Head {
  try {
    const link: Link[] = []
    const meta: Meta[] = []
    let title: string | undefined = ''

    for (let i = 0; i < metatags.length; i++) {
      const tag = metatags[i]
      const tagTitle = getTitle(tag)
      if (tagTitle) {
        title = tagTitle
      }
      else {
        const item = getTagObject(tag.attributes)
        if (tag.tag === 'link') {
          link.push(item)
        }
        else if (tag.tag === 'meta') {
          meta.push(item)
        }
      }
    }

    return { link, meta, title }
  }
  catch (e) {
    console.log(e)
  }
  return {}
}

interface UseDrupalRouteOptions {
  /**
   * Don't throw error when route is not found.
   *
   * Use this for routes not serving an entity.
   */
  noError?: boolean
}

// Overload signature to make the return type nullable when setting noError to
// true, because it allows the composable to return if no route entity is
// available.
export function useDrupalRoute<T = RouteEntityFragment>(
  query: UseDrupalRouteFragment | RouteQuery | null,
  options: { noError: true },
): Promise<T | undefined>

// Overload signature to make the return type non nullable without options or
// when the option noError is false, because the code ensures that the return
// type is always going to be the passed in generic type.
export function useDrupalRoute<T = RouteEntityFragment>(
  query: UseDrupalRouteFragment | RouteQuery | null,
  options?: { noError?: false },
): Promise<T>

/**
 * Composable that handles the Drupal routing for 404, redirects, metatags and
 * entities.
 *
 * The composable must be called directly in the top level of the <script
 * setup> code.
 */
export async function useDrupalRoute<T = RouteEntityFragment>(
  query: UseDrupalRouteFragment | RouteQuery | null,
  options?: UseDrupalRouteOptions,
): Promise<T | undefined> {
  // If the value is null it means the page does not exist.
  if (!query || !query.useDrupalRoute) {
    // If no error is requested, return.
    if (options?.noError) {
      return
    }

    // Throw an error.
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found',
      fatal: true,
    })
  }

  // Handle redirects.
  if (query.useDrupalRoute.__typename === 'RedirectUrl') {
    return navigateTo(query.useDrupalRoute.path, {
      redirectCode: query.useDrupalRoute.redirect?.statusCode ?? 302,
    }) as Promise<T>
  }

  // Handle metatags.
  if ('metatags' in query.useDrupalRoute) {
    const tags = getTags(query.useDrupalRoute.metatags)
    useHead(tags)
  }

  // At this point we have an entity and the route can be rendered.
  // Implementors might still throw an error afterwards, e.g. when the route
  // belongs to an entity that is not supported in the frontend.
  if (
    'route' in query
    && query.route?.__typename
    && (query.route.__typename === 'EntityCanonicalUrl'
    || query.route.__typename === 'DefaultEntityUrl')
    && query.route.entity
  ) {
    return query.route.entity
  }

  if (options?.noError) {
    return
  }

  throw createError({
    statusCode: 404,
    statusMessage: 'Page not found',
    fatal: true,
  })
}
