<template>
  <component
    :is="paragraphComponent"
    v-if="paragraphComponent && content"
    v-bind="mergedProps"
  />
</template>

<script setup lang="ts">
// import Explanation from '~/components/Paragraph/Explanation/index.vue'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  content: {
    type: Object,
    default: () => {
      return {}
    },
  },
  wrapper: {
    type: String,
    default: '',
  },
  nid: {
    type: Number,
    default: 0,
  },
  color: {
    type: String,
    default: 'default',
  },
})

const paragraphComponentXX = computed(() => {
  const componentName = props.type.replace('Paragraph', '')
  try {
    return resolveComponent(componentName)
  }
  catch (error) {
    console.error(`Component ${componentName} not found`)
    return null
  }
})

const paragraphComponent = computed(() => {
  switch (props.type) {
    case 'ParagraphExplanation':
      return resolveComponent('ParagraphExplanation')
    case 'HeroImage':
      return resolveComponent('HeroImage')
    case 'ParagraphHeroVideo':
      return resolveComponent('ParagraphHeroVideo')
    case 'ParagraphHowTo':
      return resolveComponent('ParagraphHowTo')
    case 'ParagraphImageText':
      return resolveComponent('ParagraphImageText')
    case 'ParagraphCallToAction':
      return resolveComponent('ParagraphCallToAction')
    case 'ParagraphCheckList':
      return resolveComponent('ParagraphCheckList')
    case 'ParagraphHappyCitizens':
      return resolveComponent('ParagraphHappyCitizens')
    case 'ParagraphSmartCity':
      return resolveComponent('ParagraphSmartCity')
    case 'ParagraphIconBanner':
      return resolveComponent('ParagraphIconBanner')
    case 'ParagraphLogos':
      return resolveComponent('ParagraphLogos')
    case 'ParagraphTestimonialSlider':
      return resolveComponent('ParagraphTestimonialSlider')
    case 'ParagraphTestimonialList':
      return resolveComponent('ParagraphTestimonialList')
    case 'ParagraphTitleCentered':
      return resolveComponent('ParagraphTitleCentered')
    case 'ParagraphKeyvisual':
      return resolveComponent('ParagraphKeyvisual')
    case 'ParagraphSteps':
      return resolveComponent('ParagraphSteps')
    case 'ParagraphSmirchImageSlider':
      return resolveComponent('ParagraphSmirchImageSlider')
    case 'ParagraphTextOnly':
      return resolveComponent('ParagraphTextOnly')
    case 'ParagraphTextBlocks':
      return resolveComponent('ParagraphTextBlocks')
    case 'ParagraphPrices':
      return resolveComponent('ParagraphPrices')
    case 'ParagraphPrimaryInvertedBanner':
      return resolveComponent('ParagraphPrimaryInvertedBanner')
    case 'ParagraphFrontendScreencastVideos':
      return resolveComponent('ParagraphFrontendScreencastVideos')
    default:
      return null
  }
})

const mergedProps = computed(() => {
  const content = props.content
  if (props.type === 'ParagraphTextOnly') {
    content.wrapper = props.wrapper
  }
  if (props.type === 'ParagraphTestimonialList') {
    content.nid = props.nid
  }

  const colorTypes = ['ParagraphCallToAction', 'ParagraphHeroImage', 'ParagraphHeroVideo']
  if (colorTypes.includes(props.type)) {
    content.color = props.color
  }

  return content
})
</script>

<style lang="scss"></style>
