<template>
  <div class="youtube-container has-text-centered">
    <div>
      <div class="ratio ratio-16x9">
        <iframe v-not-lazy
          v-if="embedUrl"
          :src="embedUrl"
          :title="video.title"
          class="video-content"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getYouTubeID } from '~/helpers/getYouTubeID'
import { getVimeoID } from '~/helpers/getVimeoID'
import type { MediaRemoteVideoFragment } from '#graphql-operations'

const props = defineProps({
  video: {
    type: Object as PropType<MediaRemoteVideoFragment>,
    default: () => {},
  },
})

enum VideoSourcePlatform {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
}

const kind = computed<VideoSourcePlatform>(() => {
  // Matching `youtu` for youtube.com and youtu.be
  if (props.video?.url?.toLowerCase().includes('youtu')) {
    return VideoSourcePlatform.YOUTUBE
  }

  if (props.video?.url?.toLowerCase().includes('vimeo')) {
    return VideoSourcePlatform.VIMEO
  }

  throw new Error('Unknown video source type for video URL')
})

const videoId = computed(() => {
  if (!props.video?.url) {
    return null
  }

  if (kind.value === VideoSourcePlatform.YOUTUBE) {
    return getYouTubeID(props.video?.url)
  }

  if (kind.value === VideoSourcePlatform.VIMEO) {
    return getVimeoID(props.video?.url)
  }

  throw new Error(`Don't know how to get ID from video: ${props.video.url}`)
})

const embedUrl = computed(() => {
  if (!props.video?.url) {
    return null
  }

  if (kind.value === VideoSourcePlatform.YOUTUBE) {
    return `https://www.youtube.com/embed/${videoId.value}?modestbranding=1&rel=0&autoplay=1&html5=1`
  }

  if (kind.value === VideoSourcePlatform.VIMEO) {
    return `https://player.vimeo.com/video/${videoId.value}?autoplay=1`
  }
})
</script>

<style lang="scss" scoped>
.modal-content {
  .modal-body {
    padding: 0;
  }
}
</style>
