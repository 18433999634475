<template>
  <div :id="`comment-${cid}`" class="comment is-size-7">
    <div class="intro">
      <nav class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <span class="initials">{{ initials }}</span>{{ userName }} <span v-if="orgName">, {{ orgName }}</span>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            {{ formatDate(changed) }}
          </div>
        </div>
      </nav>
    </div>

    <h4 class="title is-5">
      {{ subject }}
    </h4>
    <div
      v-if="body && body.processed"
      class="has-text-weight-medium"
      v-html="body.processed"
    />
  </div>
</template>

<script setup lang="ts">
import type { CommentCommentFragment, CommentFragment } from '#graphql-operations'

const props = defineProps({
  cid: {
    type: Number,
  },
  changed: {
    type: Number,
  },
  name: {
    type: String,
    default: '',
    required: false,
  },
  subject: {
    type: String,
    default: '',
    required: false,
  },
  user: {
    type: Object as PropType<CommentFragment['user']>,
    required: false,
  },
  body: {
    type: Object as PropType<CommentCommentFragment['body']>,
    required: false,
  },
})

const userName = computed(() => {
  if (props.user?.entity?.name && props.user?.entity?.firstName) {
    return `${props.user?.entity?.firstName} ${props.user?.entity?.name}`
  }

  return props.name
})

const orgName = computed(() => {
  if (props.user?.entity?.organization) {
    return props.user?.entity?.organization
  }
  return ''
})

const initials = computed(() => {
  const names = userName.value?.split(' ') ?? ''
  let initials = ''
  if (names[0]) {
    initials = names[0].substring(0, 1).toUpperCase()
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
  }
  return initials
})

const formatDate = (unix: number) => {
  const milliseconds = unix * 1000 // 1575909015000
  const dateObject = new Date(milliseconds)
  return dateObject.toLocaleDateString('de-CH', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}
</script>

<style scoped lang="scss">
.comment {
  .intro {
    font-size: 10px;
    font-weight: bold;
    margin-bottom: toRem(20);
  }

  .initials {
    color: $white;
    background-color: $grey-dark;
    height: 32px;
    width: 32px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 1rem;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  border-top: 2px solid $grey-lighter;
  padding: 1.5rem 2.5rem 3rem 2.5rem;
  background-color: $background-gray;

  &:nth-child(2n) {
    background: transparent;
  }

  &:last-of-type {
    border-bottom: 2px solid $grey-lighter;
  }
}
</style>
